<template>
  <!-- 管理员管理 -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="form" label-width="100px">
        <el-form-item label="管理员姓名">
          <el-input style="width:250px" v-model="model" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input style="width:250px" v-model="model" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-input style="width:250px" v-model="model" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-button style="margin-bottom:20px;" type="primary" icon="el-icon-plus" size="small" @click="$router.push({ name: 'UserAdd' })">添加</el-button>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="page_size"
    />
    <!-- 弹窗 -->
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="45%" :show-close="false">
      <el-form ref="form" :model="formData" label-width="100px">
        <el-form-item label="新密码">
          <el-input style="width:300px" size="small" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label=" " style="margin-top:40px">
          <el-button size="small" type=" " @click="dialogVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="savePwd">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { manageUserListApi, removeAdmin, getRoleList, changeStatus } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: '账号',
    prop: 'account',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['account']}</p>
    }
  },
  {
    label: '手机号',
    width: '150',
    prop: 'phone',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['phone']}>
          {row['phone']}
        </p>
      )
    }
  },
  {
    label: '角色',
    width: '200',
    prop: 'roleName',
    customRender(h, row) {
      return <p class="one-line">{row['role']?.['roleName']}</p>
    }
  },
  {
    label: '性别',
    width: '150',
    prop: 'sex_dsc',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['sex_dsc']}>
          {row['sex_dsc']}
        </p>
      )
    }
  },
  {
    label: '状态',
    prop: 'status',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['status']}>
          <a-switch
            disabled={row['isSuper'] == 1}
            default-checked={row['status'] == 20 ? true : false}
            onChange={() => this.changeStatus(row)}
            checked-children="开启"
            un-checked-children="禁用"
          />
        </p>
      )
    }
  },
  {
    label: '创建时间',
    width: '180',
    prop: 'created_at',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['created_at']}>
          {row['created_at']}
        </p>
      )
    }
  },
  {
    label: '操作',
    fixed: 'right',
    minWidth: '200',
    customRender(h, row) {
      return (
        <div>
          <el-link
            v-show={this.isShowBtn('UserEdit')}
            disabled={row['isSuper'] == 1}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'UserEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>
          <el-link
            v-show={this.isShowBtn('UserDel')}
            disabled={row['isSuper'] == 1}
            underline={false}
            style="margin:0 10px"
            type="primary"
            onClick={() => this.goRemove(row['id'])}
          >
            删除
          </el-link>
          <el-link
            v-show={this.isShowBtn('UserEdit')}
            disabled={row['isSuper'] == 1}
            underline={false}
            type="primary"
            onClick={() => this.updatePwd(row['id'])}
          >
            修改密码
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      dialogVisible: false,
      isShow: false,
      list: [{ id: 1 }],
      page_size: 20,
      searchData: {
        userName: '',
        mobile: ''
        // roleId: ''
      },
      total: 0,
      currentPage: 1,
      columns,
      roleList: []
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getAdminList()
    },
    page_size() {
      this.getAdminList()
    }
  },

  mounted() {
    this.getAdminList()
    // this.getRoleList()
  },

  methods: {
    async getRoleList() {
      let params = { page: 1, pageSize: 50 }
      const res = await getRoleList(params)
      this.roleList = res.list || []
    },
    async getAdminList() {
      const { userName, mobile } = this.searchData
      let params = { userName, mobile, page: this.currentPage, pageSize: this.page_size }
      let res = await manageUserListApi(params)
      this.list = res?.data || []
      this.total = res?.total || 0
    },

    goAdd() {
      this.$router.push({ name: 'MemberDetail' })
    },
    goEdit(id) {
      this.$router.push({ name: 'MemberDetail', params: { id: id || undefined } })
    },
    async goRemove(id) {
      this.$confirm('此操作将永久删除该管理员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          removeAdmin({ id }).then(() => {
            this.$message.success('删除成功')
            this.getAdminList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getAdminList()
    },
    changeStatus(row) {
      const { id, status } = row
      let data = { id, status: status == 1 ? 0 : 1 }
      changeStatus(data).then(() => {
        this.$message.success('操作成功')
        this.getAdminList()
      })
    },
    updatePwd(id) {
      this.dialogVisible = true
    },
    savePwd() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }
}
</style>
